@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Black.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Black.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Black.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Black.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Black.svg#SourceSansPro-Black') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}


@font-face {
	font-family: 'Lato';
	src: url('RESOURCE/fonts/Lato-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Lato-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}


.img-responsive {
	width: 100%;
}

.mt-0 {
	margin-top: 0;
}



.text-primary {
	color: var(--PrimaryColor);
}

.banner-big-img {
	width: 100%;
	height: 66vh;
	position: relative;

	@media (max-height: 600px) {
		height: 450px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	p {
		font-family: Raleway, sans-serif;
		font-weight: 700;
		text-shadow: 0 0 10px #000;
		font-size: 45px;
		margin: 0;
		color: #fff;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		left: 0;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: normal;

		@media (max-width: 767px) {
			font-size: 32px;
			padding: 0 10px;
			top: -50px;
			line-height: normal;
		}
	}
}

.static-searchbar {
	position: absolute;
	background: rgba(255, 255, 255, 0.5);
	width: 100%;
	bottom: 130px;
	padding-top: 20px;
	padding-bottom: 20px;

	@media (max-width: 992px) {
		bottom: 0;
	}

	.searchbar {
		.big-para {
			margin: 0;
			color: var(--PrimaryColor);
			text-align: left;

			img {
				width: 18px;
				height: auto;
				display: inline-block;
				position: relative;
				margin: -3px 0 0 0;
			}


		}

		.searchbox {
			padding: 5px;
			display: flex;
			height: 50px;
			align-items: center;


			input,
			.search-btn,
			select {
				border-radius: 0;

			}

			input[type=checkbox],
			input[type=radio] {
				width: 17px;
				height: 17px;
				border: 1px solid #ccc;
				border-radius: 3px;
				background-color: #fff;
			}

			label {
				margin: 0;
				font-size: 17px !important;
				font-weight: 500;
				padding: 0;
			}

			.search-btn {
				width: 100%;
				background-color: var(--PrimaryColor);
				border: 1px solid;
				border-color: var(--PrimaryColor);
				box-shadow: none;
				transition: all ease 0.3s;

				&:hover {
					background-color: var(--secondarycolor);
					color: #fff;
					border-color: var(--secondarycolor);
				}

				@media (min-width: 992px) and (max-width: 1199px) {
					padding: 10px 0;
				}
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}

			@media (min-width: 768px) and (max-width: 991px) {
				width: 100%;
				margin: 10px 0 0 0;
			}

		}

		@media (max-width: 767px) {
			bottom: 0;
		}

		@media (min-width:768px) {
			max-width: 100%;
			bottom: 20%;
		}

		@media (min-width:993px) {
			max-width: 100%;
		}

	}
}

.form-control,
.checkbox label:before {
	border-radius: 0 !important;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Bold.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Bold.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

.img-thumbnail {
	border-radius: 0 !important;
}

.btn {
	border-radius: 0 !important;

	&.btn-primary {
		white-space: inherit;

		&:hover {
			background-color: var(--beige) !important;
			border-color: var(--beige) !important;
		}
	}
}

.box.full-xxs.show {
	border-radius: 0 !important;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-ExtraLight.eot');
	src: url('RESOURCE/fonts/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.svg#SourceSansPro-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Light.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Light.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Light.svg#SourceSansPro-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Regular.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Regular.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-SemiBold.eot');
	src: url('RESOURCE/fonts/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bree Serif';
	src: url('RESOURCE/fonts/BreeSerif-Regular.eot');
	src: url('RESOURCE/fonts/BreeSerif-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/BreeSerif-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/BreeSerif-Regular.woff') format('woff'),
		url('RESOURCE/fonts/BreeSerif-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/BreeSerif-Regular.svg#BreeSerif-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('RESOURCE/fonts/Raleway-Thin.eot');
	src: url('RESOURCE/fonts/Raleway-Thin.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Raleway-Thin.woff2') format('woff2'),
		url('RESOURCE/fonts/Raleway-Thin.woff') format('woff'),
		url('RESOURCE/fonts/Raleway-Thin.ttf') format('truetype'),
		url('RESOURCE/fonts/Raleway-Thin.svg#Raleway-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('RESOURCE/fonts/Raleway-ExtraLight.eot');
	src: url('RESOURCE/fonts/Raleway-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Raleway-ExtraLight.woff2') format('woff2'),
		url('RESOURCE/fonts/Raleway-ExtraLight.woff') format('woff'),
		url('RESOURCE/fonts/RESOURCE/fonts/Raleway-ExtraLight.ttf') format('truetype'),
		url('RESOURCE/fonts/Raleway-ExtraLight.svg#Raleway-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('RESOURCE/fonts/Raleway-Light.eot');
	src: url('RESOURCE/fonts/Raleway-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Raleway-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/Raleway-Light.woff') format('woff'),
		url('RESOURCE/fonts/Raleway-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/Raleway-Light.svg#Raleway-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('RESOURCE/fonts/Raleway-Regular.eot');
	src: url('RESOURCE/fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Raleway-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Raleway-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Raleway-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/Raleway-Regular.svg#Raleway-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('RESOURCE/fonts/Raleway-Medium.eot');
	src: url('RESOURCE/fonts/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Raleway-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/Raleway-Medium.woff') format('woff'),
		url('RESOURCE/fonts/Raleway-Medium.ttf') format('truetype'),
		url('RESOURCE/fonts/Raleway-Medium.svg#Raleway-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('RESOURCE/fonts/Raleway-SemiBold.eot');
	src: url('RESOURCE/fonts/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Raleway-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/Raleway-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/Raleway-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/Raleway-SemiBold.svg#Raleway-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('RESOURCE/fonts/Raleway-Bold.eot');
	src: url('RESOURCE/fonts/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Raleway-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Raleway-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Raleway-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/Raleway-Bold.svg#Raleway-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

body {
	font-family: 'Lato', sans-serif;
	letter-spacing: .05em;

}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
	font-family: 'Lato', serif;
}

.checkbox-success input[type=checkbox]:checked+label:before {
	background-color: var(--PrimaryColor);
	border-color: var(--PrimaryColor);
}

.checkbox label:after,
.checkbox label:before {
	outline: none !important;
}

.img-bdr {
	object-fit: cover;
	margin: 0 0 30px;
	border: 3px solid rgb(134 134 134/80%);
	padding: 3px;
}

@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	flex: 1 0 auto;
	@mixin flexlayout column;
}

.layout-row {
	flex: 1 0 auto;
	@mixin flexlayout row;
}

.mb-20 {
	margin-bottom: 20px;
}

@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;

}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}

.block-area {
	.mar_bottom {
		margin-bottom: 60px;
		padding-top: 40px;

		@media (max-width:767px) {
			margin-bottom: 20px;
		}
	}

	h2 {
		color: var(--PrimaryColor);
		font-size: 24px;
		margin: 0 0 20px;
	}
}

@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}


.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.btn-go {
	background-color: var(--PrimaryColor);
	color: #FFF !important;
	border: 1px solid var(--PrimaryColor);

	//box-shadow: 0 1px 1px #eee;
	&:hover {
		background-color: var(--beige);
		border: 1px solid var(--beige);
	}
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
		text-align: left !important;
	}

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

.datepicker-trigger {
	--cal_not_available: #2e76bc;

	.asd__day--not-available {
		background: var(--cal_not_available) !important;
		color: var(--greycolor) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(90deg, white 70%, var(--cal_not_available) 80%) !important;
		opacity: 1 !important;
		color: rgb(86, 90, 92) !important;
	}

	.asd__day--departure {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, white 30%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, #e4e7e7 70%, var(--cal_not_available) 80%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, #e4e7e7 30%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: rgb(102, 226, 218) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: rgb(103, 246, 238) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		/*color: rgba(255, 255, 255) !important;*/

	}

	.asd__day--selected {
		background: rgb(0, 166, 153) !important;
		color: white !important;
		opacity: 1 !important;
	}


}




/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

#back_to_top {
	position: fixed;
	bottom: 50px;
	right: 20px;
	border-radius: 4px;
	border: 1px solid var(--PrimaryColor);
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	width: 50px;
	padding: 0;
	font-size: 15px;
	background: var(--PrimaryColor);
	z-index: 1000;

	i {
		margin-right: 0;
		font-size: 16px;
		color: var(--whitecolor);
	}

	&:hover {
		background-color: var(--blackcolor);
		border-color: var(--blackcolor);
	}

	@media (max-width:992px) {
		right: -54px;

		i {
			margin-right: 20px;
		}
	}
}

.pad60 {
	padding: 60px 0;

	@media (max-width:450px) {
		padding: 30px 0;
	}
}

.mt-30 {
	margin-top: 30px;
}

.pt-30 {
	padding-top: 30px !important;
}

.primarycolortext {
	color: var(--PrimaryColor);
}

#swipebox-prev,
#swipebox-next,
#swipebox-close {
	background-image: url(RESOURCE/img/icons.png) !imortant;
	background-repeat: no-repeat;
	border: none !important;
	text-decoration: none !important;
	cursor: pointer;
	width: 50px;
	height: 50px;
	top: 0;
}

.asd__day--disabled button {
	background: #fff !important;
	color: #504f4f !important;
}

.asd__day--enabled button {
	background: #fff !important;
	color: #000 !important;
}

.asd__day--not-available button {
	background: #ad1105 !important;
	color: #b1b1b1 !important;
}

.asd__day--departure button {
	background: linear-gradient(90deg, #ad1105 20%, #fff 30%) !important;
	color: #000 !important;
}

.asd__day--arrival button {
	background: linear-gradient(90deg, #fff 70%, #ad1105 80%) !important;
	color: #000 !important;
}

.asd__day--in-range button,
.asd__day--selected button,
.asd__day--hovered button {
	color: rgb(255, 255, 255) !important;
	background: rgb(102, 226, 218) !important;
	border: 1px double rgb(51, 218, 205) !important;
}

.asd__mobile-only {
	margin-top: 100px;
}

.hide-mob {
	display: block;

	@media (max-width:767px) {
		display: none;
	}
}

.show-mob {
	display: none;

	@media (max-width:767px) {
		display: block;
	}
}

.payment-view {
	padding: 100px 0 50px 0;

	h1 {
		color: var(--PrimaryColor);
		font-size: 24px;
	}

	a.active {
		background: var(--PrimaryColor);
		color: #fff;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 15px;
		font-size: 15px;
		text-decoration: none;
		border: 1px solid #ad1105;
		border: 1px solid var(--PrimaryColor);
		margin: 0 0 25px;
		max-width: 230px;
	}
}

.pay-online-box {
	.amount-options-list {
		list-style: none;
		padding: 0;
		margin: 15px 0;

		.amount-or {
			text-align: center;
			margin: 15px 0;
			font-size: 18px;
			font-weight: 500;
			color: #333;
		}

		.btn.btn-default {
			background: var(--PrimaryColor);
			color: #fff;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 15px;
			font-size: 15px;
			text-decoration: none;
			border: 1px solid #ad1105;
			border: 1px solid var(--PrimaryColor);
			margin: 0;
			width: 100%;
			border-radius: 0;

			&:hover {
				background: #0a4680;
				background: var(--secondarycolor);
				border: 1px solid #0a4680;
				border: 1px solid var(--secondarycolor);
			}
		}
	}
}


.doc-box {
	h3 {
		color: var(--PrimaryColor) !important;
		letter-spacing: .5px;
		font-size: 20px;
		margin: 0;
		padding: 0 0 15px;
		line-height: 1.1em;
	}

	p {
		font-size: 16px !important;
	}
}

.flex-row-reverse {
	@media (min-width:768px) {
		display: flex;
		flex-direction: row-reverse;
	}
}

.big-para {
	font-size: 18px;
	line-height: 28px;
}

/*.asd__day--disabled,
.asd__day--empty {
	opacity: 1 !important;
}

.asd__inner-wrapper {
	.asd__month {

		.asd__day--disabled button,
		.asd__day--empty button {
			background: var(--PrimaryColor) !important;
		}

		.asd__day-button {
			background: transparent !important;
			color: #000 !important;
		}
	}
}*/

#back_to_top {
	position: fixed;
	bottom: 1.25rem;
	right: 55px;
	z-index: 100;
	/*background-color: var(--PrimaryColor);*/
	background-color: var(--PrimaryColor);
	font-size: 1.25rem;
	padding: 1.2rem;
	display: inline-block;
	text-align: center;
	border-radius: 0 !important;

	i {
		font-size: 25px;
	}


	@media (max-width:992px) {
		bottom: 55px;
		right: 25px;
		/*height: 45px;
		width: 60px;*/

	}
}

#back_to_top:hover {
	background-color: var(--beige);
	color: #000;
	border-color: var(--beige);
}

.m-auto {
	margin: 0 auto !important;
}

/* .covid-btn {
	background: transparent;
	border: none;
	color: #337ab7;
	padding: 0;
} */

.covid-section {

	margin: 0;
	position: absolute;
	/* top: 400px;
	right: 200px; */
	right: 10%;
	top: 50%;
	z-index: 10;
	/* transform: rotate(10deg); */

	@media (max-width: 1400px) {
		right: 5%;
		top: 360px;
	}

	@media (max-width: 1200px) {
		right: 5%;
		top: 440px;
	}

	@media (max-width: 1024px) {
		right: 5%;
		top: 425px;
	}

	@media (max-width: 992px) {
		right: 5%;
		top: 285px;
	}

	@media (max-width: 768px) {
		right: 5%;
		top: 300px;
	}

	@media (max-width: 480px) {
		/* right: 10%; */
		top: 285px;
	}

	@media (max-width: 280px) {
		/* right: 10%; */
		top: 385px;
	}


	.btn-banner {
		/* width: 160px;
		height: 160px;
		border-radius: 100px;
		font-size: 24px; */

		width: auto;
		height: 80px;
		font-size: 30px;
		font-weight: 600;
		letter-spacing: 0.07em;
		color: #fff;

		background-color: var(--PrimaryColor);
		border: 1px solid var(--PrimaryColor);
		border-radius: 10px;
		box-shadow: none;
		-webkit-transition: all .3s ease;
		transition: all .3s ease;

		&:hover {
			background-color: var(--beige);
			border-color: var(--beige);
		}

		@media (max-width: 992px) {
			/* width: 220px;
			height: 80px; */
			font-size: 25px;
		}

		@media (max-width: 768px) {
			/* width: 150px; */
			height: 56px;
			font-size: 17px;
		}

		@media (max-width: 480px) {
			/* width: 130px; */
			height: 55px;
			font-size: 15px;
		}
	}
}

#covid-popup {
	h4 {
		text-align: center;
		font-size: 18px;
		/*font-family: "Bree Serif", serif;*/
	}


	.modal-dialog {
		max-width: 840px;
		width: 100%;
		padding: 20px;
		margin-top: 0;
		margin-bottom: 0;
		height: 100%;
		max-height: 840px;

		@media (max-width: 767px) {
			margin: 0 auto;
			padding: 0;
			max-height: 100%;
		}


		.modal-content {
			padding: 0;
			height: 100%;

			.modal-body {
				overflow: auto;
				height: calc(100% - 120px);
				text-align: center;

				.button-blog {
					width: 100%;
					padding: 5px 10px;
					font-size: 24px;
					color: #fff;

					background-color: #1f6e87;
					border: 1px solid #1f6e87;
					border-radius: 10px;
					box-shadow: none;
					-webkit-transition: all .3s ease;
					transition: all .3s ease;

					&:hover {
						background-color: #8f877f;
						border-color: #8f877f;
						text-decoration: none;
					}

				}
			}

		}
	}
}

.pt-50 {
	padding-top: 50px;
}

.pb-40 {
	padding-bottom: 40px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-30 {
	padding-bottom: 30px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.pb-10 {
	padding-bottom: 10px;
}

.gray-bg {
	background: #f1f1f1;
}

.asd__wrapper {
	margin: 0 auto;
}

.small,
small {
	font-size: 65%;
}

.datepicker-ext-row {
	display: flex;
	align-items: center;
	margin: 20px 0 0 0 !important;

	@media (max-width: 767px) {
		flex-wrap: wrap;

		max-width: 280px;
		-webkit-box-pack: center;
		justify-content: center;
		width: 100%;
		margin: 30px auto 0 !important;
	}

	span {
		font-size: 15px;
		margin-right: 10px;

		@media (max-width: 767px) {
			margin: 0 0 15px 0;
			max-width: 90px;
			display: inline-block;
			width: 100%;
		}
	}

	select {
		height: 40px !important;
		max-width: 130px !important;
		width: 100%;
		border-radius: 0;
		padding-left: 5px !important;
		padding-right: 5px !important;
		margin: 0 20px 0 10px !important;
		border: 1px solid rgba(0, 0, 0, .2) !important;
		background-position: 96% 16px;
		font-size: 14px !important;

		&:first-child {
			margin-right: 5px;
		}

		@media (max-width: 767px) {
			margin: 0 20px 10px 10px !important;
			max-width: 160px;
		}

		@media all and (min-width: 400px) and (max-width: 480px) {
			max-width: 180px;
		}
	}
}

.lastviewed-units {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;

		@media (max-width:576px) {
			top: 20%;
		}




		button {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border-radius: 50%;
			box-shadow: 0 0 8px rgba(0, 0, 0, .21);
			background-color: var(--PrimaryColor) !important;
			position: absolute;
			left: 0;

			@media (max-width:991px) and (min-width:600px) {
				left: -30px;
			}

			@media (min-width:1060px) {
				left: -50px;
			}


			span {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: transparent;

				&:before {
					color: var(--whitecolor);
					font-family: "Font Awesome 5 Free";
					position: relative;
					left: 2px;
					font-size: 16px;
					top: 1px;
				}
			}

			&.owl-next {
				position: absolute;
				right: 0;
				left: initial;

				@media (max-width:991px) and (min-width:600px) {
					right: -30px;
				}

				@media (min-width:1060px) {
					right: -50px;
				}

				/*	@media (max-width:992px) {
					right: -6px;
				} */

				span {

					&:before {
						content: "\f054";
						left: 3px;
					}
				}
			}

			&.owl-prev {
				span {

					&:before {
						content: "\f053";

					}
				}
			}
		}
	}
}