:root {
	--introImg: url(RESOURCE/img/1.jpg);
	--introMobileImg: url(RESOURCE/img/banner1.jpg);
	--impressumbanner: url(RESOURCE/img/impressum-banner.jpg);
	--navbarbreak: 768px;
	--whitecolor: #fff;
	--PrimaryColor: #1f6e87;
	--blackcolor: #000;
	--greycolor: #b5bec3;
	--defaultcolor: #ad1105;
	--secondarycolor: #0a4680;
	--lightprimary: #f6edd8;
	--cal_not_available: #ad1105;
	--beige: #8f877f;
	--blue: #1f6e87;
}