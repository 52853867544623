.indexpage {
	.img-bl {
		width: 100%;
		display: block;

		img {
			width: 100%;
			height: 600px;
			-o-object-fit: cover;
			object-fit: cover;
			margin: 0 0 30px;
			border: none;
			padding: 0;
		}
	}

	.btn-link {
		background: var(--PrimaryColor);
		color: #fff;
		height: 40px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0 15px;
		font-size: 15px;
		text-decoration: none;
		border: 1px solid var(--PrimaryColor);
		margin: 0 25px;
		border-radius: 3px;

		&:hover {
			background: var(--secondarycolor);
			border: 1px solid var(--secondarycolor);
		}
	}

	.text-center {
		text-align: center;
	}



	.brief {
		padding-top: 80px;
		padding-bottom: 115px;
		font-family: 'Open Sans', Helvetica Neue, Sans-serif;
		line-height: 28px;
		font-size: 16px;

		.content-section {
			margin-top: 15px;
		}

		h2 {
			margin-bottom: 15px;
			margin-top: 10px;
		}

		p {
			margin-top: 40px;
			color: #555;
		}

		ul {
			color: #555;

			.fa {
				padding-right: 10px;
			}

			a {
				color: inherit;
			}
		}

		.brief-image-right {
			margin-top: 20px;
		}

		.brief-image-right img {
			float: right;
			width: 92%;
		}

		.brief-image-left {
			margin-top: 25px;
			overflow: hidden;
		}

		.brief-image-left img {
			float: left;
			width: 95%;
		}


	}

	.grey-bg {
		border-top: 1px solid #eaebec;
		border-bottom: 1px solid #eaebec;
		background: #F7F8FA;
	}


	.feature-list {
		margin-top: 15px;
	}

	.feature-list li {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.feature-list li span {
		margin-right: 10px;
	}

	.colored-text {
		color: @introcolor;
	}

	/* COLORED BORDERS AND LINES */
	.colored-line-left,
	.colored-line-right,
	.colored-line {
		background: @introcolor;
	}

	.colored-line-left,
	.colored-line-right,
	.colored-line,
	.white-line,
	.grey-line {
		height: 2px;
		width: 80px;
		display: block;
	}

	.colored-line-left {
		float: left;
	}

	.colored-line-right {
		float: right;
	}

	.colored-line {
		margin: auto;
	}


	.small-text-medium {
		font-size: 14px;
		font-weight: 600;
	}

	.uppercase {
		text-transform: uppercase;
	}

	@media (max-width: 991px) {
		html {
			font-size: 90% !important;
		}

		.text-left,
		.text-right {
			text-align: center;
		}

		.colored-line-left {
			float: none;
			margin: auto;
		}
	}
}

.index-main-section {
	padding: 0;

	.zig-zag-section {

		.container {
			display: -webkit-flex;
			display: -webkit-box;
			display: -moz-flex;
			display: -moz-box;
			display: -ms-flexbox;
			display: flex;
			flex-direction: row;
			align-items: center;
		}



		.image {
			img {
				width: 100%;
				height: 400px;
				-o-object-fit: cover;
				object-fit: cover;
				margin: 0 0 30px;
				border: none;
				padding: 0px;
			}
		}

		.text {
			h2 {
				color: var(--PrimaryColor);
				font-weight: 500;
				letter-spacing: .5px;
				font-size: 32px;
				margin: 0 0 15px 0;
			}

			p {
				font-size: 17px;
				margin: 0 0 20px;
				line-height: 28px;
			}

			p {
				a {
					background: var(--PrimaryColor);
					color: #fff;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 15px;
					font-size: 15px;
					text-decoration: none;
					border: 1px solid var(--PrimaryColor);
					margin: 0 0 25px;
					max-width: 150px;

					&.text-link {
						margin: auto;
						max-width: inherit;
						border: none;
						background: transparent;
						color: #337ab7;
						font-size: inherit;
						display: inline-block;
						height: inherit;
						padding: inherit;

						&:hover {
							text-decoration: underline;
							background: inherit;
							border: none;
						}
					}

					&:hover {
						background: var(--secondarycolor);
						border: 1px solid var(--secondarycolor);
					}


				}
			}
		}
	}

	.after-banner {
		.row {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.center {
				padding-top: 25px;
				padding-bottom: 25px;
				text-align: center;
			}

			p {
				margin: 0;
				text-align: center;
			}
		}

		.covid-btn {
			border: none;
			background-color: transparent;
			color: #337ab7;
			text-decoration: none;
			padding: 0;
			margin: 0;

			&:hover {
				color: #23527c;
				text-decoration: underline;
			}
		}
	}

	.section3 {
		background: #f1f1f1;
		padding: 20px 0;
		margin-bottom: 30px;

		p {
			font-size: 16px;
			line-height: 28px;

		}
	}


	.section2 {
		background: #f1f1f1;
		padding: 80px 0 50px 0;
	}

	.section1 {
		background: #fff;
		padding: 50px 0 50px 0;
	}

	.section4 {
		h5 {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;

			a {
				background: var(--PrimaryColor);
				color: #fff;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 15px;
				font-size: 15px;
				text-decoration: none;
				border: 1px solid var(--PrimaryColor);
				margin: 0 0 50px;
				width: 150px;

				&:hover {
					background: var(--secondarycolor);
					border: 1px solid var(--secondarycolor);
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.index-main-section {
		.zig-zag-section {
			.image {
				img {
					height: 300px;
				}
			}

			.text {
				h2 {
					font-size: 24px;
					text-align: center;
				}

				p {
					font-size: 16px;
					line-height: 24px;
					text-align: center;
				}

				p {
					a {
						margin: 0 auto 30px;
					}
				}
			}
		}
	}
}

@media all and (min-width: 768px) and (max-width: 991px) {
	.indexpage {
		.carousel-default {
			position: relative;
		}

		.item {
			height: 700px;
		}

		.searchbar {}

	}
}

.newsletter-post-bl {
	background: rgb(241, 241, 241);
	background: linear-gradient(180deg, rgba(241, 241, 241, 1) 50%, rgba(249, 249, 249, 1) 50%);
}

.newslet-bl {
	background-color: #acbac4;
	padding: 15px;
	max-width: 1210px;
	margin: 0 auto;
	text-align: center;

	@media (max-width:992px) {
		padding: 16px 20px 10px;
	}

	.h4 {
		color: var(--whitecolor);
		font-size: 18px;
		margin-bottom: 25px;
		margin-top: 10px;
		margin-bottom: 20px;
		display: block;
	}

	p {
		color: var(--whitecolor);
	}


	.widt-cont-bl {
		color: var(--whitecolor);
		background-color: rgba(0, 0, 0, .2);
		border: 2px solid rgba(0, 0, 0, .2);
		/*border: 4px solid rgba(0, 0, 0, .2);
		padding-left: 15px;*/
		display: flex;
		max-width: 420px;
		margin: 0 auto;

		@media (max-width:992px) {
			border: none;
		}

		.form-control {
			border: none;
			background-color: transparent;
			font-size: 16px;
			height: inherit;
			padding: 10px 20px;
			color: var(--whitecolor);

			@media (max-width:992px) {
				padding: 7px 0;
				font-size: 13px;
			}
		}

		.btn-primary {
			padding: 12px 40px;
			color: var(--whitecolor);

			@media (max-width:992px) {
				padding: 7px 20px;
				font-size: 14px;
			}
		}
	}
}