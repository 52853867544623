.booking-view {

	margin: 110px 0 0 0;
	border-top: 1px solid #e4e4e4;

	.input-group {
		gap: 0;
		display: flex;
	}

	.coupon-field {
		.form-control {
			margin-right: 10px;
		}

		.btn {
			width: 140px;
			dispaly: flex;
			justify-content: center;
		}
	}

	.coupon-v1 {
		padding: 20px;
		border: 1px solid #c3c3c3;
		margin-top: 1rem !important;
		background-color: #f9f9f9;
		margin-bottom: 1rem !important;

		.h4 {
			margin-top: 0 !important;
		}
	}

	.btn-go {
		&:hover {
			background-color: var(--beige);
			border-color: var(--beige);
		}
	}

	a {
		color: var(--PrimaryColor);
		text-decoration: none !important;
	}

	.cart-details {
		margin: 50px 0 0 0;

		.unit-details {
			margin-top: 10px;
			padding-left: 20px;

			li {
				margin-left: 0px;
			}
		}

		.service-title {
			button {
				display: none;
			}
		}
	}

	.checks {
		.checkbox {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.doc-box {
				width: auto;
				margin: 0 0 0 5px;
			}
		}
	}

	.pd-form {
		label {
			font-weight: 700;
			text-align: left;
		}

		label.lrequired {
			font-weight: 700;
			text-align: left;
		}

	}

	.final-col {
		.checks {
			margin-bottom: 30px;

			label {
				font-weight: 700;
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
		border-radius: 0;
		margin-top: 10px;
	}


}