.main-footer {
	.footer-top {

		background: #f9f9f9;
		padding: 50px 0 15px 0;

		.widget {
			.about {
				padding: 0 0 30px 0;

				img {
					max-width: 180px;

				}

				ul {
					list-style: none;
					padding: 0;

					display: flex;
					align-items: center;
					width: 100%;



					li {
						padding: 0 10px;
						font-size: 30px;

						a {
							color: #acbac4;

							&:hover {
								color: var(--PrimaryColor);
							}
						}
					}
				}
			}

			h3,
			.h3 {
				color: var(--PrimaryColor);
				font-weight: 500;
				letter-spacing: 0.5px;
				font-size: 20px;
				line-height: 1.1em;
				margin: 0;
				padding: 0 0 15px 0;
				display: flex;
				width: 100%;
			}

			.useful_links {
				padding: 0;
				padding-top: 15px;
				list-style: none;
				margin: 0 0 30px 0;

				li {
					padding: 7px 0;
					border-bottom: 1px dotted hsla(0, 0%, 89.4%, .5);
					font-size: 16px;
					font-weight: 500;

					a {
						color: #929292;
						-webkit-transition: all 0.2s ease-out;
						transition: all 0.2s ease-out;
						text-decoration: none;

						&:hover {
							color: var(--PrimaryColor);
						}

						&:before {
							font-family: FontAwesome;
							content: "\f0da";
							margin-right: 12px;
							color: #acbac4;
							display: block;
							float: left;
							font-size: 0.8em;
							line-height: 1.8em;
						}
					}
				}
			}
		}

		.address_details {
			padding: 0 0 30px;
			list-style: none;
			margin: 0;

			li {
				padding: 7px 0;
				border-bottom: 1px dotted rgba(228, 228, 228, 0.5);
				font-size: 16px;
				font-weight: 500;

				i {
					margin-right: 12px;
					font-size: 1em;
					color: #acbac4;
				}

				span {
					a {
						@media (max-width:992px) and (min-width:768px) {
							display: block;
						}
					}
				}

				a {
					color: #acbac4;
					text-decoration: none;

					&:hover {
						color: var(--PrimaryColor);
					}
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					display: flex;
					font-size: 15px;
					align-items: center;
				}
			}
		}
	}

	.copyright {
		padding: 15px 0;
		background: var(--whitecolor);

		.container {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		p {
			font-size: 17px;
			margin: 0;
			padding: 0;
			color: #777777;
			font-weight: 500;

			a {
				color: var(--PrimaryColor);
				text-decoration: none;
			}

			@media (max-width: 767px) {
				text-align: center;
			}
		}

		.right {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-wrap: wrap;

			img {
				width: 130px;
			}
		}
	}

	.contact-section {
		padding: 40px 10px 80px 10px;
		text-align: center;

		p {
			color: #555;
			margin-bottom: 20px;
		}

		p.lead {
			font-size: 24px;
			margin-bottom: 10px;
		}

		.contact {
			font-size: 24px;

			.fa {
				margin: 10px;
				font-size: 36px;
			}



			color: @introcolor;
		}
	}


	footer {
		width: 100%;
		padding: 20px;
		text-align: center;

		.company-name,
		.footer-link {
			font-family: "Raleway", sans-serif;

			font-size: 18px;

			background-color: @navbgcolor;

			color: #888;


		}


		.footer-link {
			color: #888;
		}


	}

}