.blog {

	.breadcrumb {
		background: #f5f5f5;
		margin: 0 0 20px 0;

		.container {
			padding: 0;

			ol.breadcrumb {
				margin: 0;
				padding: 0;
			}
		}
	}

	.breadcrumb>li+li:before {
		content: "\f101";
		font-family: "Font Awesome 5 Free" !important;
		font-size: 10px;
		opacity: 1;
		z-index: 999;
		position: relative;
		font-weight: 600;
	}

	.inner-page-banner {
		background: url(RESOURCE/img/inner-banner.jpg);
		height: 300px;
		position: relative;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-attachment: fixed;

		.container {
			position: relative;

			h2 {
				position: absolute;
				margin: 0;
				width: 100%;
				z-index: 9;
				left: 0;
				top: 160px;
				color: #fff;
				font-weight: 400;
				letter-spacing: .5px;
				font-size: 35px;
				text-transform: uppercase;
			}
		}
	}

	.blog-entry {
		display: block;
		position: relative;
		margin-bottom: 30px;

		box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.1);

		&:hover {
			img {
				opacity: .5;
			}

			a {
				color: var(--whitecolor);
			}
		}

		img {
			width: 100%;
			left: 0;
			top: 0;
			z-index: 1;
			transition: all .25s ease 0s;
			height: 250px;
			object-fit: cover;
		}

		.blog-overlay-cont {
			position: relative;
			text-align: left;
			padding: 15px;
			background-color: var(--whitecolor);

			h3 {
				color: var(--blackcolor);
				margin-top: 0;
			}

			.bl-dt {
				color: var(--whitecolor);
				background-color: var(--PrimaryColor);
				padding: 3px 5px;
				display: inline-block;
				margin-bottom: 15px;
			}

			.tags {
				min-height: 40px;
				padding: 0;

				li {
					display: inline-block;
					color: var(--secondarycolor);
					list-style: none;
					padding-right: 15px;
					font-weight: bold;
					text-transform: capitalize;

					&:after {
						color: var(--blackcolor);
						position: relative;
						left: -3px;
					}
				}
			}
		}
	}


	.blog-header {
		margin-bottom: 10px;
	}

	h2 {
		font-size: 24px;
		margin-top: 0;
	}
}

.blogentry {
	padding-top: 0;

	.tag-box {
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			vertical-align: middle;
			margin: 0 5px 5px 0;

			a {
				background-color: var(--PrimaryColor);
				padding: 5px;
				display: block;
				color: var(--whitecolor);

			}
		}
	}
}