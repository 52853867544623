.static-view {

	.offer-box {
		position: relative;
		margin-bottom: 30px;
		padding: 30px 15px 15px;

	}

	.offer-blog {
		.offer {
			margin-bottom: 15px;
			background-color: #f9f9f9;

			width: 100%;
			border-radius: 0px;
			margin-bottom: 40px;

			@media(min-width:992px) {

				display: flex;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				flex-direction: row;

			}


			.img-col {
				min-width: 0;
				min-height: 0;
				position: relative;

				@media(min-width:992px) {

					-webkit-box-flex: 1;
					flex: 1 1 0;
				}


				.img-thumbnail {
					padding: 0px;
					background-color: #fff;
					border: none;
					border-radius: 0px;
				}

				.ribbon {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
					overflow: hidden;
					width: 110px;
					height: 110px;
					text-align: right;
				}

				.ribbon span {
					font-size: 16px;
					font-weight: 500;
					color: #fff;
					text-transform: uppercase;
					text-align: center;
					line-height: 25px;
					transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
					width: 160px;
					display: block;
					background: var(--PrimaryColor);
					/*	box-shadow: 0 3px 10px -5px #000; */
					position: absolute;
					top: 31px;
					left: -39px;

				}

			}

			.info-col {
				position: relative;
				padding: 26px 26px 0 26px;

				@media(min-width:993px) {
					width: 55%;
				}

				h4 {
					font-size: 2.5rem;
					margin: 0;
					line-height: 1;
					margin-bottom: 25px;
					display: block;

					a {
						display: inline-block;
					}
				}

				.date {
					margin-top: -10px;
				}

				.description {
					margin-top: 40px;
				}

				.button {

					text-align: right;

					@media(max-width:992px) {
						margin-top: 10px;
						margin-bottom: 10px;
					}

					@media(min-width:992px) {
						position: absolute;
						right: 26px;
						bottom: 26px;
					}

					.fa {
						padding-left: 10px;
					}
				}
			}
		}
	}


	img {
		max-width: 100%;
	}

	.breadcrumb {
		background: #f5f5f5;
		margin: 0 0 20px 0;

		.container {


			ol.breadcrumb {
				margin: 0;
				padding: 0;
			}
		}
	}

	.scroll-links {
		padding: 0;
		list-style: none;
		margin-bottom: 40px;

		li {
			margin-bottom: 15px;

			a {
				font-size: 18px;
				color: #337ab7;
			}
		}
	}

	.section-bdr {
		border-bottom: 1px solid #e6e6e6;
		padding: 0;
		margin: 0 0 50px;

		&:last-child {
			border-bottom: none;
		}

		@media (max-width:767px) {
			margin-bottom: 40px;
			padding: 0 0 20px;
		}

		.text-primary {
			@media (max-width:767px) {
				margin-top: 0;
			}
		}

		h3 {
			@media (max-width:767px) {
				margin-top: 0;
			}
		}

		.mar_bottom {
			padding-top: 20px;
			margin-bottom: 40px;

			@media (max-width:767px) {
				padding-top: 0;
				margin-bottom: 30px !important;
			}

			&:last-child {
				@media (max-width:767px) {
					margin-bottom: 0 !important;
				}
			}
		}
	}

	.breadcrumb>li+li:before {
		content: "\f101";
		font-family: "Font Awesome 5 Free" !important;
		font-size: 10px;
		opacity: 1;
		z-index: 9;
		position: relative;
		font-weight: 600;
	}

	.inner-page-banner {
		background: url(RESOURCE/img/inner-banner.jpg);
		height: 300px;
		position: relative;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-attachment: fixed;

		.container {
			position: relative;

			.h2,
			h2 {
				position: relative;
				margin: 0;
				width: 100%;
				z-index: 9;
				left: 0;
				top: 160px;
				color: #fff;
				font-weight: 400;
				letter-spacing: .5px;
				font-size: 35px;
				text-transform: uppercase;
			}
		}

		&.angebote-banner {
			background: url(RESOURCE/img/inner-banner.jpg);
		}
	}

	.buy-house-scontent {


		.container {
			display: flex;
			align-items: center;

			p a {
				color: #ad1105;
				color: var(--PrimaryColor);
				text-decoration: none;
				word-break: break-all;
			}

			h3 {

				color: var(--PrimaryColor);
			}

			.text {
				padding: 20px 0 0 0;

				p {
					font-size: 16px;
					color: #000;
					line-height: 25px;
					margin: 0 0 20px 0;
				}

			}

			.img {
				img {
					margin: 0 auto;

					padding: 80px 0;

				}
			}
		}
	}

	.guest-info-content {
		padding: 80px 0 20px 0;

		.text-row {
			margin: 0 0 30px 0;

			h4 {
				margin: 0 0 15px 0;
				font-size: 24px;
				color: var(--PrimaryColor);
			}

			p {
				font-size: 16px;
				color: #000;
				line-height: 25px;
				margin: 0 0 10px 0;
			}

			.download {
				display: flex;
				align-items: center;
				justify-content: baseline;

				.img {
					margin: 0 15px 0 0;
				}

				.text {
					display: flex;
					flex-wrap: wrap;
					align-items: center;

					span {
						width: 100%;

						a {
							background-color: var(--PrimaryColor);
							font-size: 15px;
							color: #fff;
							padding: 6px 0;
							display: flex;
							width: 120px;
							text-align: center;
							align-items: center;
							justify-content: center;
							margin: 10px 0 0 0;
							text-decoration: none;
						}
					}
				}
			}
		}
	}

	.guest-info-map {
		padding: 80px 0 80px 0;
		background: #f2f2f4;

		h3 {
			margin: 0 0 50px 0;
		}
	}

	.contact-content {
		padding: 50px 0 50px 0;

		p {
			text-align: left;
			font-size: 16px;
			line-height: 26px;
			margin: 0 0 20px;
			width: 100%;
		}

		.left {
			.section-title {
				justify-content: flex-start;
			}

			label {
				font-size: 16px;
				margin: 0 0 5px 0;
			}

			.form-control {
				border-radius: 0;
				font-size: 18px;
				margin: 0;
				background: #fff;
				box-shadow: none;
				border: 1px solid #ccc;
				width: 100%;
				height: 50px;
			}

			.btn {
				border-radius: 0;
				padding: 10px 20px;
				font-size: 15px;
			}

			textarea {
				height: 130px !important;
			}

			.checkbox label:before {
				margin-top: 3px;
			}
		}
	}

	.contact-bottom {
		background: var(--PrimaryColor);
		padding: 50px 0;

		.images {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.box {
			img {
				margin: 0 auto;
				border: 10px solid rgb(255 255 255/30%);
			}
		}
	}

	.banner-big-img {
		width: 100%;
		height: 66vh;
		position: relative;

		p {
			font-family: Raleway, sans-serif;
			font-weight: 700;
			text-shadow: 0 0 10px #000;
			font-size: 45px;
			margin: 0;
			color: #fff;
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			left: 0;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: normal;

			@media (max-width: 767px) {
				font-size: 32px;
				padding: 0 10px;
				top: -50px;
				line-height: normal;
			}
		}
	}

	&.mit-Hund {
		.index-banner {
			img {
				@media (min-width: 1200px) {
					object-position: 0 -115px;
				}
			}
		}
	}

	&.baltic-sea {



		.static-searchbar {
			position: absolute;
			background: rgba(255, 255, 255, 0.5);
			width: 100%;
			bottom: 130px;
			padding-top: 20px;
			padding-bottom: 20px;

			@media (max-width: 992px) {
				bottom: 0;
			}

			.searchbar {
				.big-para {
					margin: 0;
					color: var(--PrimaryColor);
					text-align: left;

					img {
						width: 18px;
						height: auto;
						display: inline-block;
						position: relative;
						margin: -3px 0 0 0;
					}


				}

				.searchbox {
					padding: 5px;
					display: flex;
					height: 50px;
					align-items: center;


					input,
					.search-btn,
					select {
						border-radius: 0;
						margin: 0 5px 0 0;
					}

					input[type=checkbox],
					input[type=radio] {
						width: 17px;
						height: 17px;
						border: 1px solid #ccc;
						border-radius: 3px;
						background-color: #fff;
					}

					label {
						margin: 0;
						font-size: 17px !important;
						font-weight: 500;
						padding: 0;
					}

					.search-btn {
						width: 100%;
						background-color: var(--PrimaryColor);
						border: 1px solid;
						border-color: var(--PrimaryColor);
						box-shadow: none;
						transition: all ease 0.3s;

						&:hover {
							background-color: var(--beige);
							color: #fff;
							border-color: var(--beige);
						}

						@media (min-width: 992px) and (max-width: 1199px) {
							padding: 10px 0;
						}
					}

					.search-popup-trigger {
						cursor: pointer;
					}

					.form-control:disabled,
					.form-control[readonly] {
						background-color: white;
					}

					@media (min-width: 768px) and (max-width: 991px) {
						width: 100%;
						margin: 10px 0 0 0;
					}

				}

				@media (max-width: 767px) {
					bottom: 0;
				}

				@media (min-width:768px) {
					max-width: 100%;
					bottom: 20%;
				}

				@media (min-width:993px) {
					max-width: 100%;
				}

			}
		}

		.index-banner {
			width: 100%;
			height: 66vh;
			position: relative;

			@media (max-height: 600px) {
				height: 450px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			p {
				font-family: Raleway, sans-serif;
				font-weight: 700;
				/*text-shadow: 0 0 10px #000;*/
				font-size: 45px;
				margin: 0;
				color: #fff;
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				left: 0;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				line-height: normal;

				@media (max-width: 767px) {
					font-size: 32px;
					padding: 0 10px;
					top: -50px;
					line-height: normal;
				}
			}
		}



		p {
			font-size: 18px;
			margin: 0 0 20px;
			line-height: 28px;
			/*color: #7e7e7e;*/
			color: #333;

			@media (max-width: 767px) {
				font-size: 16px;
				line-height: 24px;
			}

			a {
				color: var(--PrimaryColor);
				text-decoration: none;
				word-break: break-all;
			}
		}

		h1 {
			color: var(--PrimaryColor);
			font-weight: 500;
			letter-spacing: .5px;
			font-size: 32px;
			margin: 0 0 15px;
			line-height: normal;
		}

		h3 {
			color: var(--PrimaryColor);
			font-size: 24px;
			display: flex;
			width: 100%;
		}

		.section-title-2 {

			color: var(--PrimaryColor);
			font-weight: 500;
			letter-spacing: .5px;
			font-size: 32px;
			margin: 0 0 15px;
			line-height: normal;

			@media (max-width: 767px) {
				font-size: 24px;
			}
		}

		.Wellnessurlaub-first {
			padding: 20px 0 50px 0 !important;
		}

		.baltic-sea-secound {
			background: #f1f1f1;
		}

		.results {
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			margin: 0;

			.no-search-results {
				margin-top: 20px;
				margin-bottom: 27px;
				width: 100%;
				text-align: center;
			}

			.altheading {
				color: red;
			}

			h4,
			.h4 {
				margin-top: 0;
			}

			.unit-btn {
				.btn {
					background: var(--PrimaryColor);
					color: var(--whitecolor);
					font-weight: 400;
					font-size: 18px;
					letter-spacing: 0.5px;
					padding: 10px 10px;
					border-radius: 0;
					/*text-transform: uppercase;*/
					-webkit-transition: all ease 0.3s;
					transition: all ease 0.3s;

					&:hover {
						background: var(--secondarycolor);
						color: var(--whitecolor);
					}
				}
			}

			.search-result-properties {
				display: flex;
				text-align: center;
				justify-content: center;
				background-color: var(--beige);
				flex-wrap: wrap;
				padding: 5px;
				/*height: 50px;*/
				overflow: hidden;

				.ft-icon {
					text-align: center;
					display: inline-flex;
					align-items: center;
					color: #fff;
					border: 1px solid #fff;
					padding: 5px 10px;
					margin: 5px;
					font-weight: 700;
					height: 30px;

					.ft-title {
						margin-left: 5px;
					}
				}

				.tooltip-inner {
					max-width: 200px;
					padding: 3px 8px;
					color: #fff;
					text-align: center;
					background-color: var(--PrimaryColor);
					border-radius: 4px;
				}

				.tooltip.top .tooltip-arrow {
					border-top-color: var(--PrimaryColor);
				}

				@media (max-width: 767px) {
					height: auto;
				}
			}

			.unit-box {
				border: 0px solid var(--whitecolor);
				position: relative;
				margin-bottom: 30px;
				background-color: var(--whitecolor);

				.rent-rate {
					position: absolute;
					top: 60px;
					z-index: 1;
					right: 0;

					figure {
						background-color: rgb(173 17 5 / 0.7);
						padding: 5px 10px;
						color: var(--whitecolor);
						font-weight: bold;
					}
				}

				.unit-title {
					color: var(--whitecolor);
					padding: 10px;
					text-align: center;
					background-color: var(--PrimaryColor);
					position: relative;
					top: 0;
					width: 100%;
					z-index: 2;
					/*text-transform: uppercase;*/
					font-size: 16pt;
					font-weight: 400;
					margin: 0;
					/*font-family: Source Sans Pro, sans-serif;*/


					@media (max-width:767px) {
						font-size: 16px;
					}
				}

				.img-content {
					position: relative;

					.fav-icon {
						position: absolute;
						left: 5px;
						bottom: 5px;
						color: var(--primarycolor);
						font-size: 24px;
						cursor: pointer;

						.fa {
							color: var(--whitecolor);
						}
					}

					.owl-carousel {
						height: 220px;
						overflow: hidden;
					}

					.owl-nav {


						.owl-next {
							/* background: linear-gradient(90deg, transparent 0, rgba(0, 0, 0, .4)); */
							width: 50px;
							height: auto;
							bottom: 0;
							top: 0;
							position: absolute;
							border-radius: 0;
							font-size: 0;
							cursor: pointer;
							-webkit-transition: all .25s ease 0s;
							transition: all .25s ease 0s;
							-webkit-transform: none;
							color: #fff !important;
							font-size: 46px;
							line-height: 8px;
							text-align: center;
							right: 0;
							outline: none;
						}

						.owl-prev {
							/*background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .4)), to(transparent));
						background: linear-gradient(90deg, rgba(0, 0, 0, .4) 0, transparent); */
							filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#66000000", endColorstr="#00000000", GradientType=1);
							left: 0;
							width: 50px;
							height: auto;
							bottom: 0;
							top: 0;
							position: absolute;
							border-radius: 0;
							font-size: 0;
							cursor: pointer;
							-webkit-transition: all .25s ease 0s;
							transition: all .25s ease 0s;
							-webkit-transform: none;
							color: #FFF !important;
							font-size: 46px;
							line-height: 8px;
							text-align: center;
							outline: none;
						}
					}

					.map-marker {
						position: absolute;

						bottom: 0;
						top: inherit;
						right: 0;

						i {
							color: var(--whitecolor);
						}
					}

					img {
						width: 100%;
						height: 220px;
						object-fit: cover;
					}
				}

				.unit-cont-bl {
					padding: 0 20px;
					height: 240px;
					margin-bottom: 10px;
					overflow: hidden;

					@media (max-width: 991px) {
						height: auto;
					}

					p {
						font-size: 15px;
						color: #000;
						line-height: 25px;
						vertical-align: middle;
						overflow: hidden;
						margin: 10px 0;
					}
				}

				.unit-feat {
					background-color: var(--PrimaryColor);
				}

				.prices {
					.rentRate {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 18px;
						margin: 0 0 10px 0;
						color: #000;
					}
				}

				.alternativesPrice {
					text-align: center;
					font-size: 15px;
					margin: 0 0 10px 0;
				}

				.offers {
					background: #e4e4e4;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10px 0;
					font-size: 15px;
					color: #000;
					text-transform: capitalize;
				}
			}

			.srow {
				position: relative;

				.map-marker {
					position: absolute;
					top: 0;
					right: 0;
					display: inline-block;
					width: 50px;
					height: 50px;
					text-align: center;

					.fa-map-marker-alt {
						position: relative;
						top: 12px;
						cursor: pointer;
						font-size: 24px;
						color: #dedede;
					}

					.fa-map-marker-alt:hover,
					.fa-map-marker-alt.selected {
						color: #565a5c;
					}
				}

				.img-col {

					.img-content {

						.full-img {
							width: 100%;
							height: auto;

						}


						.fav-icon {
							position: absolute;
							cursor: pointer;
							right: 0;
							top: 0;
							color: var(--primarycolor);
							margin: 3px;
							font-size: 24px;
							width: 35px;
							text-align: center;
						}
					}
				}

				.info-col {
					position: relative;
					padding: 3px 3px 3px 6px;
					background-color: white;

					border-radius: 5px;
					box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

					.stars {
						position: absolute;
						right: 0;
						bottom: 0;
					}

					.prices {
						.price {
							font-size: 20px;
						}
					}

					.search-result-properties {
						margin-top: 5px;
						margin-bottom: 15px;
					}
				}
			}
		}

		ul.number-list {
			list-style: numbers;
			padding: 0 0 0 20px;
		}

		ul {
			font-weight: 600;

			li {
				font-size: 18px;
				margin: 0 0 20px;
				line-height: normal;
				/*color: #7e7e7e;*/
				color: #333;
				font-weight: 400;

				@media (max-width: 767px) {
					font-size: 16px;
					line-height: 24px;
				}
			}
		}

		.btn-primary {
			color: #fff;
		}

		.most-frequently {
			p {
				font-size: 18px;
				margin: 0 0 20px;
				line-height: 28px;
				/*color: #7e7e7e;*/
				color: #333;
			}
		}

		.w-100 {
			width: 100% !important;
		}
	}
}

.vacation-activities-content {
	padding: 00px 0 70px 0;

	h2 {
		color: var(--PrimaryColor);
	}

	h3 {
		//margin: 50px 0 20px 0;
		color: var(--PrimaryColor);
		font-size: 24px;
		display: flex;
		width: 100%;
	}

	img {
		margin: 20px 0;
		-o-object-fit: cover;
		object-fit: cover;
		margin: 0 0 30px;

	}

	p {
		font-size: 16px;
		margin: 0 0 20px;
		line-height: 26px;

		a {
			color: var(--PrimaryColor);
			text-decoration: none;
			word-break: break-all;
		}
	}

	.mar_bottom {
		margin-bottom: 60px;
		padding-top: 40px;
		//margin-top: -40px;
	}
}

@media (max-width: 767px) {
	.static-view {
		.inner-page-banner {
			.container {
				h2 {
					font-size: 25px;
					padding: 0;
				}
			}
		}

		.vacation-activities-content {
			h3 {
				font-size: 25px;
			}

			.mar_bottom {
				margin-bottom: 0;
			}
		}

		.contact-content {
			.left {
				.section-title {
					&:after {
						margin: 0 auto;
						left: 0;
						right: 0;
					}
				}
			}
		}
	}
}

.product-display-slide {
	padding: 50px 0 50px 0 !important;

	.border-0 {
		border: none !important;
	}

	.p-0 {
		padding: 0 !important;
	}

	.m-0 {
		margin: 0 !important;
	}

	.gallery-content-row {
		display: flex;
		flex-wrap: wrap;
		border-bottom: 1px solid #e6e6e6;
		padding: 0 0 50px 0;
		margin: 0 0 50px 0;
		width: 100%;
	}

	.slider-inner {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin: 0 0 20px 0;

		.gallery-img {
			width: calc(33.33% - 15px);
			padding: 0;
			position: relative;
			overflow: hidden !important;
			margin: 0 15px 0 0;

			&:nth-child(1) {
				width: 100%;
				padding: 0 0 0 0;
				margin: 0 0 15px 0;
				overflow: hidden !important;

				img {
					width: 100%;
					height: 300px;
					object-fit: cover;
				}
			}

			&:nth-child(4) {
				padding: 0;
				margin: 0;
				max-width: 270px;
				width: 33.33%;

				&:before {
					content: "+ more";
					position: absolute;
					color: #fff;
					background-color: rgba(0, 0, 0, .5);
					width: 100%;
					height: 100%;
					text-align: center;
					display: flex;
					padding-top: 0;
					font-weight: 700;
					align-items: center;
					justify-content: center;
				}
			}

			&:nth-child(n + 5) {
				display: none;
			}

			img {
				max-width: 100%;
				height: 110px;
				width: 100%;
				display: bloc;
				object-fit: cover;
				-webkit-transition: all 1s ease;
				-moz-transition: all 1s ease;
				-ms-transition: all 1s ease;
				-o-transition: all 1s ease;
				transition: all 1s ease;

				&:hover {
					-webkit-transform: scale(1.1);
					-moz-transform: scale(1.1);
					-ms-transform: scale(1.1);
					-o-transform: scale(1.1);
					transform: scale(1.1);
				}
			}
		}
	}

	.text-part {
		padding: 0;

		h2 {
			position: relative;
			z-index: 2;
			color: var(--PrimaryColor);
			font-size: 30px;
			text-align: center;
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			-webkit-box-pack: center;
			justify-content: flex-start;
			margin: 0 0 10px;
			padding: 0;
		}

		h4,
		.h4 {
			margin: 0 0 15px 0;
		}

		.left {
			ul {
				padding: 0 0 0 15px;
				margin: 0 0 15px 0;

				li {
					font-size: 16px;
					margin: 0 0 5px 0;
				}
			}

			h6 {
				margin: 0 0 20px 0;
				display: flex;
				align-items: center;

				a.btn {
					display: flex;
					align-items: center;
					justify-content: center;
					background: var(--PrimaryColor);
					color: #fff;
					font-size: 16px;
					height: 40px;
					padding: 0 30px;
					border-radius: 3px;
					outline: none;
					transition: all .3s ease;
					max-width: 150px;
					width: 150px;

					&:hover {
						background-color: var(--secondarycolor);
					}
				}
			}
		}

		.right {}

		.ferienhaus-anfragen {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			margin: 35px 0 0 0;
			width: 100%;

			h4 {
				position: relative;
				z-index: 2;
				color: var(--PrimaryColor);
				font-size: 30px;
				text-align: center;
				display: -webkit-box;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin: 0 0 10px;
				padding: 0;
				width: 100%;
				text-align: left;
			}

			.box {
				display: flex;
			}

			a {
				width: 33.33%;

				img {
					max-width: 90%;
				}
			}
		}
	}

	.bottom-text {
		p {
			text-align: left;
			font-size: 16px;
			line-height: 26px;
			margin: 0 0 20px 0;
			width: 100%;
		}

		h4 {
			position: relative;
			z-index: 2;
			color: var(--PrimaryColor);
			font-size: 30px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin: 15px 0 15px 0;
			padding: 0;
			text-align: left;
		}
	}
}



.impressum {
	.text-row {
		margin: 0 0 30px;

		h4 {
			margin: 0 0 15px;
			font-size: 24px;
			color: var(--PrimaryColor);
		}
	}
}

.ferienhaus-anfragen {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin: 0 0 0 0;

	.section-title {
		width: 100%;
	}

	.box {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 0 30px 0;
	}
}



@media (max-width: 767px) {

	.product-display-slide {
		.text-part {
			h3 {
				font-size: 24px;
			}

			.ferienhaus-anfragen {
				h4 {
					font-size: 24px;
				}
			}
		}

		.bottom-text {
			h4 {
				font-size: 24px;
			}
		}
	}
}

@media all and (min-width: 320px) and (max-width: 640px) {
	.product-display-slide {
		.slider-inner {
			.gallery1 {
				img {
					height: 70px;
				}

				&:nth-child(1) {
					img {
						height: 200px;
					}
				}
			}
		}
	}
}

@media all and (min-width: 641px) and (max-width: 767px) {

	.slider-inner {
		.gallery1 {
			img {
				height: 70px;
			}
		}
	}
}

@media all and (min-width: 768px) and (max-width: 991px) {
	.product-display-slide {
		.slider-inner {
			.gallery1 {
				img {
					height: 70px;
				}

				&:nth-child(1) {
					img {
						height: 200px;
					}
				}
			}
		}
	}
}



.vacation-activities-content h6 a.btn {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	background: #ad1105;
	background: var(--PrimaryColor);
	color: #fff;
	font-size: 16px;
	height: 40px;
	padding: 0 30px;
	border-radius: 3px;
	outline: none;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	max-width: 350px;
	width: 350px;

	&:hover {
		background: var(--beige);
		border-color: var(--beige);
	}
}